import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ea5e784"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "file-web-link margin-bottom" }
const _hoisted_2 = { class: "input-group" }
const _hoisted_3 = ["placeholder"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      href: "",
      class: "text-muted text-medium",
      onClick: _cache[0] || (_cache[0] = _withModifiers($event => ($setup.emit('hide-web-link')), ["prevent"]))
    }, _toDisplayString(_ctx.__("← Back to upload files")), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "form-control",
        placeholder: _ctx.__('Attach a web link'),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.url) = $event))
      }, null, 8 /* PROPS */, _hoisted_3), [
        [_vModelText, $setup.url]
      ])
    ])
  ]))
}